import React from 'react';
import styled from 'styled-components';

import AlbanyGradLogo from '../assets/img/albany_logo_2.png'
import AlbanyUndergradLogo from '../assets/img/albany_logo_1.png'
import GoldsteinLogo from '../assets/img/lmghs_logo.png'


const Container = styled.div`
h1 {
    text-align: center;
}
hr {
    margin-bottom: 100px;
}
`

const EducationContainer = styled.div`
    display: flex;
    gap: 50px;
    justify-content: space-around;
    flex-flow: row wrap;
`

const EducationHolder = styled.div`
z-index: 2;
position: relative;
padding-left: 15px;
padding-right: 15px;
padding-bottom: 15px;
background-color: lightgray;
color: black;
margin-right: 10px;
margin-left: 10px;
border-radius: 25px;
align-items: center;
box-shadow: 0 0 20px #fff;           
max-width: 500px;
p {
    text-align: center;
    font-size: 1.3rem;
    margin: 0;
}
img {
    max-width: 175px;
    margin-top: -90px;
    display: block;
  margin-left: auto;
  margin-right: auto;
}
`

const EducationText = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
@media only screen and (max-width: 900px) {
    p {
        font-size: .9rem;
    }
  }
`

const educationData = [
    {
        title: "Master Degree in Computer Science",
        name: "University at Albany: Master of Science in Computer Science",
        completion: "May 2020",
        extra: "GPA: 3.49",
        logo: AlbanyGradLogo
    },
    {
        title: "Bachelor Degree in Computer Science",
        name: "University at Albany: Bachelor of Science in Computer Science",
        completion: "May 2019",
        extra: "GPA: 3.64, Magna Cum Laude, Deans List (x5)",
        logo: AlbanyUndergradLogo
    },
    {
        title: "High School Diploma",
        name: "Leon M. Goldstein High School for the Sciences (Brooklyn, NY)",
        completion: "June 2015",
        extra: "Advanced Regents Diploma",
        logo: GoldsteinLogo
    }
]

const Education = () => {
    return (
        <Container id="education">
            <h1>Education</h1>
            <hr />
            <EducationContainer>
            {educationData
                .map((school, index) => (
                    <EducationHolder key={index}>
                        <img alt={school.logo + " logo"} src={school.logo} />
                        <EducationText>
                        <p>{school.title}</p>
                        <p>{school.name}</p>
                        <p>{school.completion}</p>
                        <p>{school.extra}</p>
                        </EducationText>
                    </EducationHolder>
                ))}
                </EducationContainer>
        </Container>
    );
};

export default Education;