import React, {useState, useEffect, useRef} from 'react';

import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

const ExperienceHolder = styled.div`
text-align: center;
width: 65vw;
background-color: lightgray;
color: black;
margin: 0 auto;
margin-bottom: 75px;
border-radius: 25px;
box-shadow: 100px -25px #6183A6, 0 0 20px #fff;

ul {
    text-align: left;
    list-style-type: square;
    li {
        padding: 10px;
    }
}
h1 {
    padding-left: 0px;
    margin: 0px;
    font-size: 2em;
}
img {
    margin-top: 15px;
    max-width: 250px;
}
p {
    margin: 0px;
}
@media only screen and (max-width: 600px) {
    width: 95vw;
}
`

const Container = styled.div`
    z-index: 2;
    position: relative;
`

const Center = styled.div`
    position:relative;
    top: -350px;
`

const ExperienceItem = ({ job}) => {
    const targetRef = useRef();

    const [targetElement, setElement] = useState();
    useEffect(() => {
      setElement(targetRef.current);
    }, []);
  
    return (
        <Container>
            <Center ref={targetRef}/>
            <Parallax
                translateX={job.translate}
                targetElement={targetElement}
            >
                <ExperienceHolder>
                    <img alt={job.company + " logo"} src={job.logo} />
                    <h1>{job.company}</h1>
                    <p>{job.title}</p>
                    <p>{job.from} - {job.to}</p>
                    <ul>
                        {job.bullets
                            .map((bullet, index) => (
                                <li key={index}>{bullet}</li>
                            ))}
                    </ul>
                </ExperienceHolder>
            </Parallax>
        </Container>
    );
};

export default ExperienceItem;