import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
h1 {
    text-align: center;
}
padding-bottom: 60px;
`

const Description = styled.p`
    text-align: center;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    a {
        color: lightblue;
        text-decoration: none;
    }
`

const Contact = () => {
    return (
        <Container id="contact">
            <h1>Contact</h1>
            <hr />
            <Description>To get in touch for any inquiries or just to say hello, please feel free to send me an email at: <a href="mailto:info@abrahamzakharov.com" target="_blank" rel="noopener noreferrer">info@abrahamzakharov.com</a>.</Description>
        </Container>
    );
};

export default Contact;