import './App.css';
import Contact from './Components/Contact';
import Experience from './Components/Experience';
import Navigation from './Components/Navigation';
import Projects from './Components/Projects';
import Education from './Components/Education';
import Header from './Components/Header';
import Skills from './Components/Skills';
import About from './Components/About';

import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
  return (
    <div className="App">
      <ParallaxProvider>
        <Navigation />
        <Header />
        <About />
        <Skills />
        <Experience />
        <Projects />
        <Education />
        <Contact />
      </ParallaxProvider>
      <div className="main"></div>
    </div>
  );
}

export default App;
