import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
h1 {
    text-align: center;
}
`

const Description = styled.p`
    text-align: center;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    a {
        color: lightblue;
        text-decoration: none;
    }
`

const SkillIcons = styled.div`
    font-size: 5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
`

const Icon = styled.div`
    display: flex;
    flex-direction: column;
    span {
        font-size: 1rem;
        text-align: center;
    }
    i:hover {
        color:gray;
    }
`

const Skills = () => {
    return (
        <Container id="skills">
            <h1>Skills</h1>
            <hr />
            <Description>The following is a summary of notable technologies that I have experience with. Although I am ready and willing to learn anything that would be required of me.</Description>
            <SkillIcons>
                <Icon>
                    <i className="devicon-java-plain"></i>
                    <span>Java</span>
                </Icon>
                <Icon>
                    <i className="devicon-python-plain"></i>
                    <span>Python</span>
                </Icon>
                <Icon>
                    <i className="devicon-c-plain"></i>
                    <span>C</span>
                </Icon>
                <Icon>
                    <i className="devicon-typescript-plain"></i>
                    <span>Typescript</span>
                </Icon>
                <Icon>
                    <i className="devicon-angularjs-plain"></i>
                    <span>Angular</span>
                </Icon>
                <Icon>
                    <i className="devicon-react-original"></i>
                    <span>React</span>
                </Icon>
                <Icon>
                    <i className="devicon-javascript-plain"></i>
                    <span>Javascript</span>
                </Icon>
                <Icon>
                    <i className="devicon-nodejs-plain"></i>
                    <span>Node.js</span>
                </Icon>
                <Icon>
                    <i className="devicon-express-original-wordmark"></i>
                    <span>Express</span>
                </Icon>
                <Icon>
                    <i className="devicon-html5-plain-wordmark"></i>
                    <span>HTML</span>
                </Icon>
                <Icon>
                    <i className="devicon-css3-plain-wordmark"></i>
                    <span>CSS</span>
                </Icon>
                <Icon>
                    <i className="devicon-mysql-plain"></i>
                    <span>MySQL</span>
                </Icon>
                <Icon>
                    <i className="devicon-microsoftsqlserver-plain"></i>
                    <span>MS SQL Server</span>
                </Icon>
            </SkillIcons>
            <Description>My full list of software engineering experience includes: Java, Python, C, C#, JavaScript, TypeScript, HTML, CSS, Node.js, Express.js, REST API Development, Angular, React, Vue.js, SQL, NoSQL, MySQL, MongoDB, Firebase, Bootstrap, Visual Studio, MS SQL Server, Git, AWS, Azure, Heroku, NGINX, and Docker.</Description>
        </Container>
    );
};

export default Skills;