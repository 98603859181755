import React from 'react';
import styled from 'styled-components';

import x4Image from '../assets/img/x4-screen.png'
import ChewieImage from '../assets/img/chewiecoin-screen.png'
import StockImage from '../assets/img/stocklist-screen.png'
import MovieImage from '../assets/img/sydmovies-screen.png'

const projectsData = [
    {
        name: "X4 Production Chart",
        bullet: "Built a simple, intuitive, and interactive production chart for the video game X4: Foundations, aiding 50,000+ players.",
        stack: "Created using React.",
        live: "https://x4prodchart.com",
        github: "https://github.com/AbrahamZak/x4prodchart",
        image: x4Image
    },
    {
        name: "Chewie Coin",
        bullet: "Launched Chewie Coin, a decentralized crypto token currently available on the open market.",
        stack: "Website made with React",
        live: "https://chewiecoin.com",
        image: ChewieImage
    },
    {
        name: "Stocklist",
        bullet: "Formulated a financial platform to search information about 10,000+ stocks (company information, financial data, sentiment, news), charts, create accounts, maintain a watchlist.",
        stack: "Stack: React, Finnhub API, MongoDB, Node.js, Express.js, and Heroku.",
        live: "https://StockList.AbrahamZakharov.com",
        github: "https://github.com/AbrahamZak/stocklist",
        image: StockImage

    },
    {
        name: "Sydmovies",
        bullet: "Find your favorite movies, create accounts, add favorites, see scores from the top 3 platforms, and includes its own review system.",
        stack: "Stack: Angular, Firebase user authentication, Firebase NoSQL database, Bootstrap, and the OMDB API.",
        live: "https://Sydmovies.AbrahamZakharov.com",
        github: "https://github.com/AbrahamZak/Sydmovies",
        image: MovieImage
    }
]

const Container = styled.div`
h1 {
    text-align: center;
}
`
const ProjectContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    row-gap: 30px;
    column-gap: 30px;
`

const ProjectHolder = styled.div`
    z-index: 2;
    position: relative;
    background-color: lightgray;
    color: black;
    width: 375px;
    height: 800px;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
    border-radius: 5px;
    box-shadow: 0 0 20px #fff; 
    a {
        display: inline-block;
        background-color: black;
        color: white;
        text-decoration: none;
        padding: 15px;
        border-style: solid black;
        border-radius: 5px;
        margin: 5px 10px;
        font-weight: bold;
        width: 35%;
        transition-duration: 0.4s;
        &:hover{
            background-color: white;
            color: black;
        }
    }
    img {
        width: 100%;
        height:400px;
    }
`

const ProjectTitle = styled.p`
    font-size: 23px;
    font-weight: bold;
`

const ProjectTextContainer = styled.div`
    font-size: 15px;
    padding: 0px 10px;
    height: 300px;
`

const Description = styled.p`
    text-align: center;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    a {
        color: lightblue;
        text-decoration: none;
    }
`

const Projects = () => {
    return (
        <Container id="projects">
            <h1>Projects</h1>
            <hr />
            <Description>Below are projects I've worked on in the past. You can also see my <a href="https://github.com/abrahamzak" target="_blank" rel="noopener noreferrer">GitHub</a> for more examples of my work.</Description>
            <ProjectContainer>
                {projectsData
                    .map((project, index) => (
                        <ProjectHolder key={index}>
                            <img alt={project.image + " image"} src={project.image} />
                            <ProjectTextContainer>
                                <ProjectTitle>{project.name}</ProjectTitle>
                                <p>{project.bullet}</p>
                                <p>{project.stack}</p>
                            </ProjectTextContainer>
                            <a href={project.live} target="_blank" rel="noopener noreferrer">Live</a>
                            {project.github ? (
                                <a href={project.github} target="_blank" rel="noopener noreferrer">GitHub</a>
                            ) : (
                                null
                            )}
                        </ProjectHolder>
                    ))}
            </ProjectContainer>
        </Container>
    );
};

export default Projects;