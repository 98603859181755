import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

import resume from '../assets/resume.pdf';

const Background = styled.section`
  background-color: black;
  width: 100%;
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const MainText = styled.p`
  padding-left: 15px;
  margin: 0;
  float: left;
  font-style: bold;
  font-size: 40px;
  padding-top: 10px;
  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 600px) {
    font-size: 33px;
    margin-top: 7px;
  }
`;

const SubText = styled.p`
  padding-left: 15px;
  text-align: left;
  margin: 0;
  margin-top: -2px;
  font-size: 20px;
  font-style: bold;
  :hover {
    cursor: pointer;
  }
`;

const LinkHolder = styled.div`
  @media only screen and (max-width: 1450px) {
    display: none;
  }
`;

const NavLink = styled.p`
  text-align: center;
  padding-top: 25px;
  padding-right: 30px;
  margin: 0;
  float: right;
  font-size: 18px;
  font-style: bold;
  width: 115px;
  :hover {
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: white;
  }
  a:hover{
    border-style: solid 1px black;
    border-radius: 15px;
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 10px;
  }
`;

const HamburgerHolder = styled.div`
  background-color: black;
  display: none;
  @media only screen and (max-width: 1450px) {
    display: block;
  }
`;

const HamburgerButton = styled.p`
  padding-right: 20px;
  line-height: 80px;
  margin: 0;
  float: right;
  font-size: 50px;
  font-style: bold;
  :hover {
    cursor: pointer;
  }
`;

const Hamburger = styled.div`
  height: 100vh;
  clear: both;
  padding-top: 50px;
`;

const HamburgerLinkHolder = styled.div`
  display: inline-block;
  padding-bottom: 30px;
  padding-left: 15px;
  width: 100%;
`;

const HamburgerLink = styled.p`
  font-size: 3rem;
  font-style: bold;
  color: white;
  margin: 0;
  a {
    text-decoration: none;
    color: white;
  }
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Navigation = () => {
  const [hamburger, setHamburger] = useState("☰");
  return (
    <Background>
      <Link to="header" offset={-150} smooth={true}>
      </Link>
      <MainText>
        <Link to="header" offset={-150} smooth={true}>
          Abraham Zakharov
        </Link>
      </MainText>
      <LinkHolder>
      <NavLink><a href={resume} target="_blank" rel="noopener noreferrer">Resume</a></NavLink>
        <NavLink>
          <Link to="contact" smooth={true}>
            Contact
          </Link>
        </NavLink>
        <NavLink>
          <Link to="education" smooth={true} offset={-100}>
            Education
          </Link>
        </NavLink>
        <NavLink>
          <Link to="projects" smooth={true} offset={-100}>
            Projects
          </Link>
        </NavLink>
        <NavLink>
          <Link to="experience" smooth={true} offset={-100}>
            Experience
          </Link>
        </NavLink>
        <NavLink>
          <Link to="skills" smooth={true} offset={-100}>
            Skills
          </Link>
        </NavLink>
        <NavLink>
          <Link to="about" smooth={true} offset={-100}>
            About Me
          </Link>
        </NavLink>
      </LinkHolder>
      <HamburgerHolder>
        <HamburgerButton
          onClick={() => {
            hamburger === "☰" ? setHamburger("X") : setHamburger("☰");
          }}
        >
          {hamburger}
        </HamburgerButton>
        {hamburger === "X" ? (
          <Hamburger>
            <HamburgerLinkHolder>
              <HamburgerLink>
                <Link
                  to="about"
                  offset={-125}
                  smooth={true}
                  onClick={() => {
                    hamburger === "☰" ? setHamburger("X") : setHamburger("☰");
                  }}
                >
                  About Me
                </Link>
              </HamburgerLink>
            </HamburgerLinkHolder>

            <HamburgerLinkHolder>
              <HamburgerLink>
                <Link
                  to="skills"
                  offset={-100}
                  smooth={true}
                  onClick={() => {
                    hamburger === "☰" ? setHamburger("X") : setHamburger("☰");
                  }}
                >
                  Skills
                </Link>
              </HamburgerLink>
            </HamburgerLinkHolder>

            <HamburgerLinkHolder>
              <HamburgerLink>
                <Link
                  to="experience"
                  offset={-100}
                  smooth={true}
                  onClick={() => {
                    hamburger === "☰" ? setHamburger("X") : setHamburger("☰");
                  }}
                >
                  Experience
                </Link>
              </HamburgerLink>
            </HamburgerLinkHolder>

            <HamburgerLinkHolder>
              <HamburgerLink>
                <Link
                  to="projects"
                  offset={-100}
                  smooth={true}
                  onClick={() => {
                    hamburger === "☰" ? setHamburger("X") : setHamburger("☰");
                  }}
                >
                  Projects
                </Link>
              </HamburgerLink>
            </HamburgerLinkHolder>

            <HamburgerLinkHolder>
              <HamburgerLink>
                <Link
                  to="education"
                  offset={-100}
                  smooth={true}
                  onClick={() => {
                    hamburger === "☰" ? setHamburger("X") : setHamburger("☰");
                  }}
                >
                  Education
                </Link>
              </HamburgerLink>
            </HamburgerLinkHolder>

            <HamburgerLinkHolder>
              <HamburgerLink>
                <Link
                  to="contact"
                  offset={-100}
                  smooth={true}
                  onClick={() => {
                    hamburger === "☰" ? setHamburger("X") : setHamburger("☰");
                  }}
                >
                  Contact
                </Link>
              </HamburgerLink>
            </HamburgerLinkHolder>

            <HamburgerLinkHolder>
              <HamburgerLink> <a href={resume} target="_blank" rel="noopener noreferrer">Resume</a></HamburgerLink>
            </HamburgerLinkHolder>

          </Hamburger>
        ) : null}
      </HamburgerHolder>
      <br /><br /><br />
      <SubText>
        Software Engineer
      </SubText>
    </Background>
  );
};

export default Navigation;
