import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
h1 {
    text-align: center;
}
`

const Description = styled.p`
    text-align: center;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    a {
        color: lightblue;
        text-decoration: none;
    }
`

const About = () => {
    return (
        <Container id="about">
            <h1>About Me</h1>
            <hr />
            <Description>2020 Computer Science master’s graduate with valuable experience gained in both professional and academic environments. Proven ability to succeed and learn in demanding environments and deliver quality code on deadlines.</Description>
        </Container>
    );
};

export default About;