import React from 'react';
import styled from 'styled-components';
import { Typewriter } from 'typewriting-react';
import { SocialIcon } from 'react-social-icons';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import Abraham from '../assets/img/me.png'

const Container = styled.div`
    text-align: center;
    padding-bottom: 50px;
    @media only screen and (max-width: 600px) {
        height: 75vh;
    }
`

const Typer = styled.p`
    font-size: 5rem;
    color: white;
    margin-bottom: 0px;
    margin-top: 0px;
    @media only screen and (max-width: 1050px) {
        font-size: 2rem;
        margin-top: 50px;
        margin-bottom: 50px;
    }
`

const SocialContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 35px;
    @media only screen and (max-width: 600px) {
        gap: 10px;
        margin-top: 10px;
        svg {
            height: 75% !important;
            width: 75% !important;
        }
    }

`

const HeaderImage = styled.img`
    height: 69vh;
    @media only screen and (max-width: 1050px) {
        height: 48vh;
    }
`

const ParaContainer = styled.div`
    position: relative;
    z-index: 2;
`

const Header = () => {

    return (
        <Container id="header">
            <ParaContainer>
            <Typer>I am a <Typewriter cursorClassName='cursor' typingSpeed={100} words={['software engineer', 'web developer', 'data scientist', 'backend engineer']} /></Typer>
            <ParallaxProvider>
                <Parallax scale={[1, 0.7, 'easeInQuad']} opacity={[2, 0]}>
                    <HeaderImage src={Abraham} alt="Picture of Abraham Zakharov and dog" />
                </Parallax>
            </ParallaxProvider>
            </ParaContainer>
            <SocialContainer>
                <SocialIcon target="_blank" rel="noopener noreferrer" style={{ height: 90, width: 90 }} url="https://www.linkedin.com/in/abraham-zakharov/" />
                <SocialIcon target="_blank" rel="noopener noreferrer" style={{ height: 90, width: 90 }} url="https://www.facebook.com/AbrahamZakharov/" />
                <SocialIcon target="_blank" rel="noopener noreferrer" style={{ height: 90, width: 90 }} url="https://github.com/abrahamzak/" />
                <SocialIcon target="_blank" rel="noopener noreferrer" style={{ height: 90, width: 90 }} url="mailto:abrahamzakharov@gmail.com" />
            </SocialContainer>
        </Container>
    );
};

export default Header;